import type { HttpHandler } from 'msw';

const _withBaseUrl = (path: string) => `/api${path}`;

/**
 * MSWのイベントハンドラー定義
 *
 * NOTE:
 * API仕様書に記載のあるリクエストは Orval が MSW のイベントハンドラーを自動生成するが、
 * 上書きしたい場合はここで定義
 */
export const handlers: HttpHandler[] = [];
